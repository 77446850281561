<template>
	<div id="Battlehistory">
		<div class="Battlehistory_nav">
			<p :class="{ 'active' : branch == '1' }" @click="calendar('1')">所有对战记录</p>
			<p :class="{ 'active' : branch == '0' }" @click="calendar('0')">我的对战记录</p>
		</div>
		<div class="Battlehistory_box">
			<div class="Battlehistory_list">
				<div class="Battlehistory_item">
					<div class="boxInfo">盲盒信息</div>
					<div class="player">玩家</div>
					<div class="Price">总额</div>
				</div>
				<div class="Battlehistory_item" v-for="(item,index) in RecordArr" :key="index">
					<div class="boxInfo">
						<img :src="i.cover | ImgFilter" alt="" v-for="(i,index) in item.game_arena_box" :key="index">
					</div>
					<div class="player">
						<div class="pic" v-for="(k,index) in item.game_arena_player" :key="index" :class="k.user_id ,item.win_user_id | ClassFilter ">
							<img :src="k.avatar | ImgFilter" alt="">
						</div>
					</div>
					<div class="Price">
						<img src="@/assets/images/public/Gold.png" alt=""><span>{{item.total_bean}}</span>
					</div>
				</div>
			</div>
			
			<el-pagination style="margin-top: 20px;" background layout="prev, pager, next" :total="total" :key="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { ArenaHistory, ArenaMeHistory } from '@/network/api.js'
	export default{
		name:'Battlehistory',
		data(){
			return{
				branch:'1',//所有/我的
				RecordArr:[],//记录列表
				total:0,//数据总数
				per_page:0,//单页数量
				page:1,//页码
			}
		},
		
		created() {
			this.record()
		},
		
		filters:{
			ClassFilter(id,Zid){
				let A_Arr = Zid.find((i) => {
					return i == id
				})
				if(A_Arr != undefined){
					return 'active'
				}
			}
		},
		
		methods:{			
			//记录切换
			calendar(v){
				this.page = 1
				this.branch = v
				this.record()
			},
			
			//记录判断
			record(){
				if(this.branch == '1'){
					this.Allrecord()
				}else{
					this.Merecord()
				}
			},
			
			//所有记录
			Allrecord(){
				ArenaHistory(this.page).then((res) => {
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
					this.RecordArr = res.data.data.data
				})
			},
			
			//我的记录
			Merecord(){
				ArenaMeHistory(this.page).then((res) => {
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
					this.RecordArr = res.data.data.data
				})
			},
			
			
			// 记录分页
			handleCurrentChange(v){
				this.page = v
				this.record()
			}
		}
	}
</script>

<style lang="scss">
::v-deep .btn-prev{
	background: url('../../assets/images/q2/tui/leftright.png') no-repeat center !important;
	background-size: 100% 100% !important;
}
	#Battlehistory{
		padding: 10px;
		box-sizing: border-box;
		.Battlehistory_nav{
			width: 100%;
			height: 30px;
			display: flex;
			p{
				height: 30px;
				padding: 0 10px;
				text-align: center;
				line-height: 30px;
				color: #fff;
				font-size: 12px;
				margin-right: 10px;
				background: #77b300;
				border-radius: 4px;
				&.active{
					background: #4854c9;
				}
			}
		}
	
		.Battlehistory_box{
			margin-top: 10px;
			width: 100%;
			
			.Battlehistory_list{
				width: 94%;
				min-height: 310px;
				background: #20222b;
				background-size: 100% 100%;
				// border-radius: 6px;
				background: url('../../assets/images/q2/tui/topbac.png') no-repeat center !important;
				background-size: 100% 100% !important;
				padding: 10px;
				.Battlehistory_item{
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					color: #fff;
					font-size: 12px;
					border-top: 1px solid #525aa5;
					&:first-child{
						border: none;
					}
					.boxInfo{
						width: 150px;
						padding-left: 10px;
						box-sizing: border-box;
						img{
							width: 20px;
							height: 20px;
							vertical-align: middle;
							margin-right: 2px;
						}
					}
					.player{
						width: 100px;
						display: flex;
						align-items: center;
						.pic{
							width: 20px;
							height: 20px;
							margin-right: 4px;
							border-radius: 50%;
							position: relative;
							img{
								width: 100%;
								height: 100%;
								vertical-align: middle;
								border-radius: 50%;
							}
							&.active{
								border: 1px solid #e9b10e;
								&::after{
									display: block;
									content:"";
									width: 10px;
									height: 10px;
									background: url(../../assets/images/Vs/crown.png) no-repeat center;
									background-size: contain;
									transform: rotate(45deg);
									position: absolute;
									right: -5px;
									top: -4px;
								}
							}
						}
					}
					.Price{
						img{
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
						span{
							font-size: 10px;
							color: #f1bc60;
						}
					}
				}
			}
		}
	
	}
</style>
